<template>
  <div id="about">
    <div class="notice">
      <van-notice-bar
        :text="notice"
        left-icon="volume-o"
        background="#eee"
        :scrollable="true"
        color="#000"
      />
    </div>

    <div class="swiper">
      <van-swipe :autoplay="3000" style="height: 100%">
        <van-swipe-item v-for="(item, index) in swipers" :key="index">
          <!-- <img  :src="require(`../assets/image/${item}`)" /> -->
          <img v-lazy="require(`../assets/image/${item}`)" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="tags">
      <van-row type="flex">
        <van-col
          v-for="(item, index) in tags"
          :key="index"
          span="8"
          @click="routerPush(item)"
        >
          <img :src="require(`../assets/image/${item.url}`)" />
          <p>{{ item.title }}</p>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { logOut, untieAPP, getCode } from "@/api/goods";
import { showConfirmDialog } from "vant";

export default {
  data() {
    return {
      //微信授权
      userimg: "../assets/image/f.png",
      isWechat: true, // 是否为微信环境（true：是；false：否）
      wx: {
        // wxAppId: "wx7b97b35020cc9fbb", // 测试微信公众号 ID
        // wxSecret: "8ad7214474ca466ff315b9924e5db01d", // 测试微信公众号 密钥
        wxAppId: "wx025f104ccad1549a", // 微信公众号 ID
        wxSecret: "38fa2b85d99dc5d487f17d1e88ca7858", // 微信公众号 密钥
        code: "", // 授权获取的 code
        openid: "", // 授权获取的 openid
        unionid: "", // 授权获取的用户唯一标识 unionid
      },
      query: {
        a: "",
        b: "",
      },

      notice: "成都东软学院微信借阅系统。",
      swipers: [
        "cddr_1.jpg",
        "cddr_2.jpg",
        "cddr_3.jpg",
        "cddr_4.jpg",
        "cddr_5.jpg",
        "cddr_6.jpg",
        "cddr_7.jpg",
        "cddr_8.jpg",
      ],
      tags: [
        { id: 1, url: "b.svg", title: "图书借阅", router: "Borrow" },
        { id: 2, url: "zj.svg", title: "我的在借", router: "Renew" },
        { id: 3, url: "yuyue.svg", title: "借阅记录", router: "borrowRecord" },
        // {
        //   id: 3,
        //   url: "password.svg",
        //   title: "修改密码",
        //   router: "Changepassword",
        // },
        // { id: 4, url: "yuyue.svg", title: "图书预约", router: "Reserve" },
        // { id: 5, url: "yujie.svg", title: "图书预借", router: "Advance" },
        // { id: 6, url: "card.svg", title: "个人信息", router: "Card" },
        // { id: 7, url: "my.svg", title: "我的预约", router: "My" },
        // { id: 8, url: "my.svg", title: "退出登录" },
        // { id: 9, url: "my.svg", title: "解除绑定" },
        // { id: 8, url: "f.png", title: "虚拟图书馆", router: "Navigation" },
        // { id: 9, url: "f.png", title: "登录", router: "Login" },
        // { id: 10, url: "zc.svg", title: "账号绑定", router: "Register" },
      ],
    };
  },
  created() {
    // if(!localStorage.getItem('openId')){
    // this.handleAuthorize();
    // }
    // if (window.location.search) {
    //   let query = window.location.search.substr(1);
    //   var strs = query.split("&");
    //   let code = strs[0].substring(strs[0].indexOf("=") + 1);
    //   // let state = strs[1].substring(strs[1].indexOf("=") + 1);
    //   console.log(code);
    // }
  },
  mounted() {
    // let token = this.getToken("reader_token");
    // let code = this.getToken("code");
    // if (token) {
    //   localStorage.setItem("token", token);
    // }
    // if (code) {
    //   sessionStorage.setItem("code", code);
    //   getCode({
    //     code,
    //   }).then((res) => {
    //     localStorage.setItem("openId", res.data.data.openId);
    //   });
    // }
    // window.history.replaceState();
  },
  methods: {
    //微信授权
    handleAuthorize() {
      /**
       * 判断是否是微信环境
       * 是微信环境：进行授权处理
       * 不是微信环境：不进行授权处理
       */
      if (!this.isWechat) return;
      /**
       * 判断是否有 微信公众号 ID和密钥
       * 该判断根据个人情况，决定是否需要
       * 一般如果需要前端传给后台接口则需要；如果不需要前端传给后台接口则不需要
       */
      if (!this.wx.wxAppId || !this.wx.wxSecret) return;
      /**
       * 这里使用了mint-ui框架，这条代码是loading展示
       * 可根据个人情况设置
       */
      // Indicator.open('微信授权中...');
      /**
       * 检查页面路径带参状况,以此来判断是否授权
       * 授权成功后：重定向路径上会带有相应的参数（code、state）
       * 授权前：页面路径上不会带有code、state参数（个人自定义参数最好不要使用这两个）
       */
      let pagePath = decodeURIComponent(window.location.href); // 页面路径

      let urlArray = pagePath.split("?");
      let WXAuthorize = window.sessionStorage.getItem("hasAuthorize") || false; // 是否已经微信网页授权
      let redirectQuery = ""; // 重定向自定义传参

      // 判断初始路径（授权前）上是否带参，以便之后写入重定向路径中
      if (urlArray.length > 1) {
        // 带参情况
        let query = urlArray[1].split("#")[0].split("&");

        query.forEach((item) => {
          let queryName = item.split("=")[0];
          let queryValue = item.split("=")[1];

          /**
           * 判断是授权后的重定向，还是授权前
           * true：已授权；false：未授权
           */
          if (WXAuthorize) {
            if (queryName === "state" && queryValue !== "authorize") {
              // 获取自定义参数
              let customQuery = queryValue.split("|");

              customQuery.forEach((queryItem) => {
                let customName = queryItem.split("=")[0];
                let customValue = queryItem.split("=")[1];

                /**
                 * 这里根据自己的实际带参情况进行判断
                 * 以原始 https://www.baidu.com?a=1&b=2 路径为例
                 */
                this.query[customName] = customValue;
              });
            } else if (queryName === "code") {
              // 获取微信授权后的code
              this.wx.code = queryValue;
            }
          } else {
            this.query[queryName] = queryValue;
          }
        });
      }

      // 判断是否已经微信授权
      if (WXAuthorize) {
        /**
         * 在授权成功后，将缓存中的授权成功记录清除
         * hasAuthorize：
         *              authorize：已授权
         *              没有该字段：未授权
         */
        // window.sessionStorage.removeItem("hasAuthorize");
        /**
         * 通过code换取openid等信息
         * 授权成功，获取code值后的逻辑，根据自己的实际情况编写
         */

        return false;
      } else {
        /**
         * 缓存记录页面初始（即授权前）路径
         * 主要用于解决在页面手动刷新时，微信授权问题
         */
        !window.sessionStorage.getItem("WXAuthorizeUrl") &&
          window.sessionStorage.setItem("WXAuthorizeUrl", window.location.href);
        // 缓存记录已授权
        window.sessionStorage.setItem("hasAuthorize", "authorize");
        // 处理重定向带参
        for (let i in this.query) {
          if (redirectQuery) {
            redirectQuery += `|${i}=${this.query[i]}`;
          } else {
            redirectQuery += `${i}=${this.query[i]}`;
          }
        }
      }

      // 微信授权路径
      let WXAuthorizeUrl = window.sessionStorage.getItem("WXAuthorizeUrl");
      // 微信公众号 ID
      let appid = this.wx.wxAppId;
      // 授权后重定向的回调链接地址，请使用 urlEncode 对链接进行处理
      let redirectUri = encodeURIComponent(WXAuthorizeUrl);
      // let redirectUri = 'http://library.gttss.com/#/login';

      // 返回类型，请填写code
      let responseType = "code";
      /**
       * 应用授权作用域 snsapi_base、snsapi_userinfo
       * snsapi_base：不弹出授权页面，直接跳转，只能获取用户openid（静默授权）
       * snsapi_userinfo：弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息
       */
      let scope = "snsapi_userinfo";
      // 重定向后会带上state参数，开发者可以填写a-zA-Z0-9的参数值，最多128字节
      let state = redirectQuery || "authorize";
      // 授权路径
      let authorizeUrl =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        redirectUri +
        "&response_type=" +
        responseType +
        "&scope=" +
        scope +
        "&state=" +
        state +
        "&connect_redirect=1#wechat_redirect";
      // 进行授权操作，获取code信息(code作为换取access_token的票据，每次用户授权带上的code将不一样，code只能使用一次，5分钟未被使用自动过期)
      window.location.replace(authorizeUrl);
      // this.$router.push({name:'Login'})
    },
    getToken(name) {
      let qs = location.search.substring(1);
      for (let item of qs.split("&").map((val) => val.split("="))) {
        return name == item[0] ? item[1] : "";
      }
    },
    routerPush(item) {
      if (item.router) {
        this.$router.push({ name: item.router });
      } else if (item.id == 8) {
        //退出登录
        if (localStorage.getItem("token")) {
          this.$dialog
            .confirm({
              title: "退出登录",
              message: "是否退出登录",
            })
            .then(() => {
              logOut().then((res) => {
                if (res.data.code == 200) {
                  this.$toast.success("退出登录成功");
                  localStorage.removeItem("token");
                } else {
                  this.$toast.fail("退出登录失败");
                }
              });
            })
            .catch(() => {
              // on cancel
            });
        }
      } else if (item.id == 9) {
        //解除绑定
        if (localStorage.getItem("token")) {
          this.$dialog
            .confirm({
              title: "解除绑定",
              message: "是否解除绑定",
            })
            .then(() => {
              untieAPP().then((res) => {
                if (res.data.code == 200) {
                  this.$toast.success("解除绑定成功");
                  localStorage.removeItem("token");
                } else {
                  this.$toast.fail("解除绑定失败");
                }
              });
            })
            .catch(() => {
              // on cancel
            });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#about {
  height: 100%;
  width: 100%;
  background: #fff url(../static/image/bg.png) no-repeat top;
  background-size: contain;
  .notice {
    padding-top: 10px !important;
    .van-notice-bar {
      width: 90%;
      margin: auto;
      border-radius: 12px;
      box-sizing: border-box;
    }
  }
  .swiper {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    height: 200px;
    .van-swipe {
      border-radius: 12px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tags {
    padding: 20px 10px;
    .van-col {
      img {
        width: 72px;
        height: 72px;
      }
      p {
        font-size: 15px;
        line-height: 20px;
      }
    }
    // .van-col:hover{
    //   background: rgba(80, 77, 77, 0.5);
    //   border: 1px solid #eee;
    //   border-radius: 3px;
    // }
  }
}
</style>
